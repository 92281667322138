.GradeCalculatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  /* width: 500px; */
  margin: 0;
  padding-bottom: 10px;
  padding-right: 30px;
  border-radius: 20px;
}
.GradeCalculatorTitle {
  font-size: 32px;
  font-weight: bolder;
  align-self: flex-start;
  margin-bottom: 12px;
  margin-top: 0;
}
.GradeCalculatorSub {
  align-self: flex-start;
  margin-bottom: 38px;
  font-size: 18px;
  margin-top: 0px;
}
.GradeCalculatorGrade {
  font-weight: bolder;
}
.GradeCalculatorBottom {
  align-self: flex-start;
  margin-bottom: 34px;
  font-size: 22px;
}
.GradeInfoBottom {
  align-self: flex-start;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 40px;
}
.GradeCalculatorExplTitle  {
  align-self: flex-start;
  margin-top: 0;
}
.GradeCalculatorExplText  {
  align-self: flex-start;
  margin-top: 0;
}
.GradeCalculatorGradeExtraText {
  display: block;
  position: absolute;
  font-size: 12px;
  margin-top: -5px;
  color: var(--small-text-color);
}
.Grade-see-which {
  font-size: 16px;
  color: var(--small-text-color);
  text-decoration: underline;
}
.GradeCalculatorSaveGrades {
  cursor: pointer;
  font-size: 14px;
  /* border: 1px solid var(--line-color); */
  text-decoration: underline;
  margin-left: 32px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
}
.GradeCalculatorSaveGradesImgPreview {
  max-height: 60px;
  margin-left: 24px;
  border-radius: 4px;
}