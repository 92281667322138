.SchoolItem-mini-item {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: background-color .1s;
  position: relative;
  box-sizing: border-box;
  /* border-top: 1px solid rgb(130, 130, 130); */
}
.SchoolItem-mini-item:hover {
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.SchoolItem-mini-open {
  background-color: var(--secondary-color);
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
}
.SchoolItem-mini-open .SchoolItem-mini-title-container {
  padding-bottom: 12px;
}
.SchoolItem-mini-line {
  height: 2px;
  width: 100%;
  background-color: rgb(200, 200, 200, 0.7);
  margin-top: 4px;
}
.SchoolItem-layout-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.SchoolItem-mini-school-title {
  font-size: 1.4em;
  font-weight: bolder;
  margin: 0;
  color: var(--small-title-color);
  margin-bottom: 0px;
  word-break: break-word;
}
.SchoolItem-mini-school-program {
  font-weight: normal;
  font-size: .9em;
  margin: 0;
  color: var(--sub-title-color);
  line-height: 1.2;
  word-break: break-word;
}
.SchoolItem-mini-score-table {
  /* align-self: flex-end; */
  margin-bottom: -3px;
  color: var(--sub-title-color);
  margin-left: 20px;
}
.SchoolItem-mini-data-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 2px;
  margin-left: 10px;
}
.SchoolItem-mini-data-row-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  /*position: absolute;
  top: 35%;
  right: 24px;*/
}
.SchoolItem-mini-data-row-container:not(.SchoolItem-mini-data-row-container-column) {
  border-bottom: 2px dashed #a9a8a7;
  padding-bottom: 4px;
}
.SchoolItem-mini-data-row-container-column {
  flex-direction: column;
  align-items: flex-end;
}
.SchoolItem-mini-data-row-title {
  text-align: right;
  font-size: 9px;
}
.SchoolItem-mini-data-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  margin-top: 2px;
  align-self: flex-end;
  width: 100%;
  max-width: 360px;
}
.SchoolItem-mini-data-container .SchoolItem-mini-data-row-container {
  margin-top: 10px;
}
.SchoolItem-mini-data-bii {
  display: block;
  font-size: 10px;
}
.SchoolItem-mini-score-data {
  font-size: 1.2em;
  padding-right: 1px;
}
.SchoolItem-mini-score-text {
  font-size: 1em;
  font-weight: lighter;
}
.SchoolItem-mini-score-data {
  font-weight: bold;
  vertical-align: baseline;
}
.SchoolItem-mini-score-text {
  font-weight: normal;
  vertical-align: baseline;
}
a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}
