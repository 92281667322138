.WelcomeHeader-container {
  margin-bottom: 8px;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.WelcomeHeader-title {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-weight: bolder;
  font-size: 3em;
  margin-bottom: 4px;
  margin-top: 10px;
}
.WelcomeHeader-sub-span {
  text-decoration: underline;
  /* cursor: pointer; */
}
.WelcomeHeader-sub {
  margin: 0;
  font-weight: normal;
  font-size: 1.3em;
}
