.SortBy-Container {
  margin-left: 8px;
  background-color: var(--secondary-color);
  height: 50px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 5px;
  font-size: 10px;
  color: var(--text-color);
  cursor: pointer;
  transition: opacity 1s;
}
.SortBy-Container .SortBy-line {
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 10px;
}
.SortBy-line-top {
  margin-top: 4px;
}
.SortBy-Container .SortBy-line::after {
  content: '';
  display: block;
  background-color: var(--sub-title-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 4px;
  left: 3px;
}
.SortBy-Container .SortBy-line:nth-child(3)::after {
  right: 13px;
}
.SortBy-Container .SortBy-line:nth-child(2)::after {
  right: 22px;
}
.SortBy-Container .SortBy-line:nth-child(4)::after {
  right: 28px;
}
.SortBy-Active {
  background-color: var(--light-primary-color);
  color: var(--secondary-color);
}
.SortBy-Active .SortBy-line::after {
  background-color: var(--secondary-color);
}
.SortBy-text {
  width: 100%;
  text-align: left;
  letter-spacing: 0.2px;
  margin-left: 4px;
  margin-bottom: -2px;
}
.SortBy-Hidden {
  opacity: 0;
  visibility: hidden;
}
