.SchoolList-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  /* width: 100%; */
  margin: 0;
  padding: 0;
  width: 100%;
}
.SchoolList-description {
  color: var(--small-long-text-color);
  margin-top: 0;
}
.SchoolList-list > * {
  margin-bottom: 36px;
}
.SchoolList-popular {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 34px;
  align-self: flex-start;
  margin-top: 42px;
  margin-bottom: 22px;
  color: var(--title-color);
  font-weight: bolder;
}
.SchoolList-showing-results {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 34px;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--title-color);
  font-weight: bolder;
}
.SchoolList-noFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
  font-weight: normal;
}
.SchoolList-noFound-image {
  height: 160px;
  margin-bottom: 40px;
}
.SchoolList-search-whole-button {
  text-decoration: underline;
  cursor: pointer;
}
.SchoolList-short-help {
  align-self: flex-start;
  margin-top: -8px;
  margin-bottom: 12px;
}
@media screen and (max-width: 650px) {
  .SchoolList-list {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .App-top {
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    padding: 2px;
  }
}
.Filter-details {
  align-self: flex-start;
  margin-bottom: 6px;
}
.Filter-summary {
  margin-bottom: 10px;
}
.SchoolList-filters {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  flex-wrap: wrap;
}
.SchoolList-popular-description {
  align-self: flex-start;
}