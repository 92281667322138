.Badge-container {
  /* width: 70px; */
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(220, 220, 220);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: black;
  font-weight: bolder;
  text-align: center;
}
.Badge-color-1 {
  background-color: #87ce84;
  letter-spacing: 0px;
  font-size: 12px;
  width: 73px;
}
.Badge-color-2 {
  background-color: #efc092;
  font-size: 13px;
  width: 34px;
}
.Badge-color-3 {
  background-color: #e29ccf;
  letter-spacing: 0px;
  font-size: 11px;
  width: 72px;
}
.Badge-color-4 {
  background-color: #98d1e2;
  width: 50px;
}
.Badge-color-5 {
  background-color: #ed9693;
  font-size: 12px;
  width: 50px;
}
.Badge-color-6 {
  font-size: 11px;
  width: 57px;
}
