@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:500,700);
html {
}
body {
  font-family: sans-serif;
  margin: 0;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap'); */
:root {
  --color-body-bg: white;
  --color-text-color: black;
  --color-title-color: rgb(21, 19, 19);/*rgb(35, 29, 29);*/
  --color-small-title-color: rgb(35, 29, 29); /*rgb(74, 53, 52);*/
  --color-sub-title-color: rgb(44, 36, 34);
  --color-small-text-color: var(--text-color);
  --color-small-long-text-color: #484848;
  --color-line-color: #404040;
  --color-primary-color: var(--text-color);
  --color-light-primary-color: #909090;
  --color-secondary-color: rgb(243, 241, 241);
  --color-shadow-color: #b9b9b9;

  --accent-color: #e0a068;

  --dark-color-body-bg: black;
  --dark-color-text-color: white;
  --dark-color-title-color: rgb(211, 211, 211);
  --dark-color-small-title-color: rgb(235, 235, 235);
  --dark-color-sub-title-color: rgb(195, 195, 195);
  --dark-color-small-text-color: var(--text-color);
  --dark-color-small-long-text-color: #cfcfcf;
  --dark-color-line-color: #c7c7c7;
  --dark-color-primary-color: var(--text-color);
  --dark-color-light-primary-color: #909090;
  --dark-color-secondary-color: rgb(57, 57, 57);
  --dark-color-shadow-color: var(--body-bg);

  --body-bg: var(--color-body-bg);
  --text-color: var(--color-text-color);
  --title-color: var(--color-title-color);
  --small-title-color: var(--color-small-title-color);
  --sub-title-color: var(--color-sub-title-color);
  --small-text-color: var(--color-small-text-color);
  --small-long-text-color: var(--color-small-long-text-color);
  --line-color: var(--color-line-color);
  --primary-color: var(--color-primary-color);
  --light-primary-color: var(--color-light-primary-color);
  --secondary-color: var(--color-secondary-color);
  --shadow-color: var(--color-shadow-color);
}
::-webkit-input-placeholder {
  color: #484848;
  color: var(--small-long-text-color)
}
::-moz-placeholder {
  color: #484848;
  color: var(--small-long-text-color)
}
:-ms-input-placeholder {
  color: #484848;
  color: var(--small-long-text-color)
}
::-ms-input-placeholder {
  color: #484848;
  color: var(--small-long-text-color)
}
::placeholder {
  color: #484848;
  color: var(--small-long-text-color)
}
body {
  background-color: white;
  background-color: var(--body-bg);
  color: black;
  color: var(--text-color);
  font-family: 'IBM Plex Sans', sans-serif;
  padding:  2px;
}
a {
  cursor: pointer;
}
.App {
  z-index: 1;
  background-color: white;
  background-color: var(--body-bg);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.App-overlay {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  -webkit-filter: none;
          filter: none;
  background-color: rgba(248, 245, 248, 0.8);
  display: flex;
  flex-direction: column;
  color: black;
  z-index: 100;
  overflow: scroll;
}
.App-overlay-close {
  font-size: 24px;
  font-weight: bolder;
  cursor: pointer;
  padding-right: 20px;
  padding-top: 10px;
  margin-bottom: -20px;
  align-self: flex-end;
}
.App-hidden {
  visibility: hidden;
}
.App-overlay-table {
  width: 80px;
  margin-left: 40px;
}
.App-overlay p {
  font-weight: normal;
  line-height: 1.5;
  margin: 20px;
}
.App-overlay:not(.App-hidden) ~ * {
  -webkit-filter: blur(4px);
          filter: blur(4px);
  z-index: 1;
}
.App-overlay-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  font-size: 28px;
  margin: 20px;
}
.App-top {
  z-index: 2;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  background-color: white;
  background-color: var(--body-bg);
  /* box-shadow: 0 2px 5px #00000038; */
  /* border-radius: 12px; */
}
.App-footer {
  /* z-index: 2; */
  height: 80px;
  /* background-color: rgb(243, 241, 241); */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-top: 3px dashed #404040;
  border-top: 3px dashed var(--line-color);
}
.App-emoji {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  margin-bottom: 2px;
}
.App-footer-sub {
  font-size: 10px;
  margin-top: 4px;
}
.App-footer-sub-link {
  text-decoration: underline;
  cursor: pointer;
}
.App-nav-active {
  background-color: black;
  background-color: var(--primary-color);
  color: white;
  color: var(--body-bg);
  border-radius: 2px;
}
.App-grade-small-pill {
  position: absolute;
  top: -12px;
  right: -10px;
  border-radius: 8px;
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  color: white;
  color: var(--body-bg);
  background-color: black;
  background-color: var( --primary-color);
}
.App-nav-active .App-grade-small-pill {
  display: none;
}
.about-link {
  text-decoration: underline;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark'
  }
  :root:not([data-color-mode]) {
    --body-bg: var(--dark-color-body-bg);
    --text-color: var(--dark-color-text-color);
    --title-color: var(--dark-color-title-color);
    --small-title-color: var(--dark-color-small-title-color);
    --sub-title-color: var(--dark-color-sub-title-color);
    --small-text-color: var(--dark-color-small-text-color);
    --small-long-text-color: var(--dark-color-small-long-text-color);
    --line-color: var(--dark-color-line-color);
    --primary-color: var(--dark-color-primary-color);
    --light-primary-color: var(--dark-color-light-primary-color);
    --secondary-color: var(--dark-color-secondary-color);
    --shadow-color: var(--dark-color-shadow-color);
  }
}

[data-color-mode='dark'] {
  --body-bg: var(--dark-color-body-bg);
  --text-color: var(--dark-color-text-color);
  --title-color: var(--dark-color-title-color);
  --small-title-color: var(--dark-color-small-title-color);
  --sub-title-color: var(--dark-color-sub-title-color);
  --small-text-color: var(--dark-color-small-text-color);
  --small-long-text-color: var(--dark-color-small-long-text-color);
  --line-color: var(--dark-color-line-color);
  --primary-color: var(--dark-color-primary-color);
  --light-primary-color: var(--dark-color-light-primary-color);
  --secondary-color: var(--dark-color-secondary-color);
  --shadow-color: var(--dark-color-shadow-color);
}


.secret-santa-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #841616;
  color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 44px;
}
.secret-santa-inner-container {
  padding: 10px;
  margin: 20px;
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  /* min-height: 90%; */
  display: flex;
  background-color: #841616;
  border: 8px dashed white;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* overflow-y: scroll; */
}
.secret-santa-name-title {
  margin-top: 60px;
  text-align: center;
  font-size: 44px;
}
.secret-santa-sub-title {
  /* padding: 4px; */
  font-size: 18px;
  text-align: center;
  max-width: 600px;
}
.secret-santa-fluff {
  width: 30%;
  max-width: 400px;
  position: absolute;
  top: 0;
  right: 0;
}

.GradeCalculatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  /* width: 500px; */
  margin: 0;
  padding-bottom: 10px;
  padding-right: 30px;
  border-radius: 20px;
}
.GradeCalculatorTitle {
  font-size: 32px;
  font-weight: bolder;
  align-self: flex-start;
  margin-bottom: 12px;
  margin-top: 0;
}
.GradeCalculatorSub {
  align-self: flex-start;
  margin-bottom: 38px;
  font-size: 18px;
  margin-top: 0px;
}
.GradeCalculatorGrade {
  font-weight: bolder;
}
.GradeCalculatorBottom {
  align-self: flex-start;
  margin-bottom: 34px;
  font-size: 22px;
}
.GradeInfoBottom {
  align-self: flex-start;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 40px;
}
.GradeCalculatorExplTitle  {
  align-self: flex-start;
  margin-top: 0;
}
.GradeCalculatorExplText  {
  align-self: flex-start;
  margin-top: 0;
}
.GradeCalculatorGradeExtraText {
  display: block;
  position: absolute;
  font-size: 12px;
  margin-top: -5px;
  color: var(--small-text-color);
}
.Grade-see-which {
  font-size: 16px;
  color: var(--small-text-color);
  text-decoration: underline;
}
.GradeCalculatorSaveGrades {
  cursor: pointer;
  font-size: 14px;
  /* border: 1px solid var(--line-color); */
  text-decoration: underline;
  margin-left: 32px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
}
.GradeCalculatorSaveGradesImgPreview {
  max-height: 60px;
  margin-left: 24px;
  border-radius: 4px;
}
.GradePicker-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  width: 100%;
  margin-bottom: 40px;
}
.GradePicker-grades {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}
.GradePicker-subj {
  font-size: 22px;
  padding-top: 6px;
  font-weight: normal;
  color: var(--small-title-color);
}
.GradePicker-grade {
  background-color: rgba(135, 129, 129, 0.3);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin: 0;
  font-size: 18px;
}
.GradePicker-grade:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.GradePicker-grade:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.GradePicker-active {
  background-color: #87ce84;
  font-weight: bolder;
}
@media screen and (max-width: 580px) {
  .GradePicker-container {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .GradePicker-subj {
    margin-bottom: 10px;
  }
  .GradePicker-grades {
    justify-content: flex-start;
  }
}

.ColorModeSwitch-container {
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.ColorModeSwitch-Item {
  text-align: center;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ColorModeSwitch-Item span:first-child {
  font-size: 28px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.ColorModeSwitch-small {
  font-size: 8px;
}
.ColorModeSwitch-active {
  background-color: var(--secondary-color);
}

.WelcomeHeader-container {
  margin-bottom: 8px;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.WelcomeHeader-title {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-weight: bolder;
  font-size: 3em;
  margin-bottom: 4px;
  margin-top: 10px;
}
.WelcomeHeader-sub-span {
  text-decoration: underline;
  /* cursor: pointer; */
}
.WelcomeHeader-sub {
  margin: 0;
  font-weight: normal;
  font-size: 1.3em;
}

.SchoolList-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  /* width: 100%; */
  margin: 0;
  padding: 0;
  width: 100%;
}
.SchoolList-description {
  color: var(--small-long-text-color);
  margin-top: 0;
}
.SchoolList-list > * {
  margin-bottom: 36px;
}
.SchoolList-popular {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 34px;
  align-self: flex-start;
  margin-top: 42px;
  margin-bottom: 22px;
  color: var(--title-color);
  font-weight: bolder;
}
.SchoolList-showing-results {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 34px;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--title-color);
  font-weight: bolder;
}
.SchoolList-noFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
  font-weight: normal;
}
.SchoolList-noFound-image {
  height: 160px;
  margin-bottom: 40px;
}
.SchoolList-search-whole-button {
  text-decoration: underline;
  cursor: pointer;
}
.SchoolList-short-help {
  align-self: flex-start;
  margin-top: -8px;
  margin-bottom: 12px;
}
@media screen and (max-width: 650px) {
  .SchoolList-list {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .App-top {
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    padding: 2px;
  }
}
.Filter-details {
  align-self: flex-start;
  margin-bottom: 6px;
}
.Filter-summary {
  margin-bottom: 10px;
}
.SchoolList-filters {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  flex-wrap: wrap;
}
.SchoolList-popular-description {
  align-self: flex-start;
}
.SchoolItem-mini-item {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
  position: relative;
  box-sizing: border-box;
  /* border-top: 1px solid rgb(130, 130, 130); */
}
.SchoolItem-mini-item:hover {
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.SchoolItem-mini-open {
  background-color: var(--secondary-color);
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
}
.SchoolItem-mini-open .SchoolItem-mini-title-container {
  padding-bottom: 12px;
}
.SchoolItem-mini-line {
  height: 2px;
  width: 100%;
  background-color: rgb(200, 200, 200, 0.7);
  margin-top: 4px;
}
.SchoolItem-layout-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.SchoolItem-mini-school-title {
  font-size: 1.4em;
  font-weight: bolder;
  margin: 0;
  color: var(--small-title-color);
  margin-bottom: 0px;
  word-break: break-word;
}
.SchoolItem-mini-school-program {
  font-weight: normal;
  font-size: .9em;
  margin: 0;
  color: var(--sub-title-color);
  line-height: 1.2;
  word-break: break-word;
}
.SchoolItem-mini-score-table {
  /* align-self: flex-end; */
  margin-bottom: -3px;
  color: var(--sub-title-color);
  margin-left: 20px;
}
.SchoolItem-mini-data-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 2px;
  margin-left: 10px;
}
.SchoolItem-mini-data-row-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  /*position: absolute;
  top: 35%;
  right: 24px;*/
}
.SchoolItem-mini-data-row-container:not(.SchoolItem-mini-data-row-container-column) {
  border-bottom: 2px dashed #a9a8a7;
  padding-bottom: 4px;
}
.SchoolItem-mini-data-row-container-column {
  flex-direction: column;
  align-items: flex-end;
}
.SchoolItem-mini-data-row-title {
  text-align: right;
  font-size: 9px;
}
.SchoolItem-mini-data-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  margin-top: 2px;
  align-self: flex-end;
  width: 100%;
  max-width: 360px;
}
.SchoolItem-mini-data-container .SchoolItem-mini-data-row-container {
  margin-top: 10px;
}
.SchoolItem-mini-data-bii {
  display: block;
  font-size: 10px;
}
.SchoolItem-mini-score-data {
  font-size: 1.2em;
  padding-right: 1px;
}
.SchoolItem-mini-score-text {
  font-size: 1em;
  font-weight: lighter;
}
.SchoolItem-mini-score-data {
  font-weight: bold;
  vertical-align: baseline;
}
.SchoolItem-mini-score-text {
  font-weight: normal;
  vertical-align: baseline;
}
a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.Badge-container {
  /* width: 70px; */
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(243, 241, 241);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: black;
  font-weight: bolder;
  text-align: center;
}
.Badge-color-1 {
  background-color: #87ce84;
  letter-spacing: 0px;
  font-size: 12px;
  width: 73px;
}
.Badge-color-2 {
  background-color: #efc092;
  font-size: 13px;
  width: 34px;
}
.Badge-color-3 {
  background-color: #e29ccf;
  letter-spacing: 0px;
  font-size: 11px;
  width: 72px;
}
.Badge-color-4 {
  background-color: #98d1e2;
  width: 50px;
}
.Badge-color-5 {
  background-color: #ed9693;
  font-size: 12px;
  width: 50px;
}
.Badge-color-6 {
  font-size: 11px;
  width: 57px;
}

.SearchBar-input {
  font-family: inherit;
  width: 100%;
  font-size: 23px;
  border-style: none;
  outline: none;
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 10px;
  border-radius: 5px;
  /* border: 2px solid var(--accent-color); */
  -webkit-appearance: none;
}
.SearchBar-input-form {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.SortBy-Container {
  margin-left: 8px;
  background-color: var(--secondary-color);
  height: 50px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 5px;
  font-size: 10px;
  color: var(--text-color);
  cursor: pointer;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.SortBy-Container .SortBy-line {
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 10px;
}
.SortBy-line-top {
  margin-top: 4px;
}
.SortBy-Container .SortBy-line::after {
  content: '';
  display: block;
  background-color: var(--sub-title-color);
  border-radius: 2px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 4px;
  left: 3px;
}
.SortBy-Container .SortBy-line:nth-child(3)::after {
  right: 13px;
}
.SortBy-Container .SortBy-line:nth-child(2)::after {
  right: 22px;
}
.SortBy-Container .SortBy-line:nth-child(4)::after {
  right: 28px;
}
.SortBy-Active {
  background-color: var(--light-primary-color);
  color: var(--secondary-color);
}
.SortBy-Active .SortBy-line::after {
  background-color: var(--secondary-color);
}
.SortBy-text {
  width: 100%;
  text-align: left;
  letter-spacing: 0.2px;
  margin-left: 4px;
  margin-bottom: -2px;
}
.SortBy-Hidden {
  opacity: 0;
  visibility: hidden;
}

.Filter-Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 12px;
}
.Filter-title {
  margin: 0;
  align-self: flex-start;
  color: var(--small-long-text);
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}
.Filter {
  /* margin-left: 10px; */
}

.Filter-Icon-Container {
  padding-left: 8px;
  padding-right: 8px;
  height: 26px;
  cursor: pointer;
  color: var(--text-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
}
.Filter-Icon-Container:nth-child(1) {
  margin-left: 0;
}
.Filter-Icon-Active {
  background-color: var(--small-title-color);
  border-color: var(--light-primary-color);
  color: var(--body-bg);
}

.SchoolItem-item {
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
  /* border-top: 1px solid rgb(130, 130, 130); */
}
.SchoolItem-item:hover {
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.SchoolItem-open {
  background-color: var(--secondary-color);
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
}
.SchoolItem-open .SchoolItem-title-container {
  padding-bottom: 12px;
}
.SchoolItem-line {
  height: 2px;
  width: 100%;
  background-color: rgb(200, 200, 200, 0.7);
  margin-top: 4px;
}
.SchoolItem-school-title {
  color: var(--title-color);/*rgb(21, 19, 19);*/
  margin-top: 0;
  word-break: break-word;
}
.SchoolItem-school-program {
  font-weight: normal;
  font-size: .9em;
  margin: 0;
  color: var(--sub-title-color);
  line-height: 1.2;
  word-break: break-word;
}
.SchoolItem-score-table {
  /* align-self: flex-end; */
  margin-bottom: -3px;
  color: var(--sub-title-color);
  margin-left: 20px;
}
.SchoolItem-data-title-container {
  display: flex;
  justify-content: space-between;
}
.SchoolItem-data-row {
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 2px;
}
.SchoolItem-data-row-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
}
.SchoolItem-data-row-container:not(.SchoolItem-data-row-container-column) {
  padding-bottom: 4px;
}
.SchoolItem-data-row-container-column {
  flex-direction: column;
  align-items: flex-end;
}
.SchoolItem-data-row-title {
  margin-right: 16px;
}
.SchoolItem-data-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  background-color: var(--secondary-color);
  border-radius: 2px;
  box-shadow: -1px 2px 5px 0px var(--shadow-color);
  /* border: 2px solid; */
  padding: 10px;
}
.SchoolItem-data-share {
  text-decoration: underline;
  cursor: pointer;
}
.SchoolItem-data-container > .SchoolItem-data-title-container > h3 {
  font-weight: lighter;
}
.SchoolItem-data-container > .SchoolItem-data-title-container > h3 > span {
  font-weight: bold
}
.SchoolItem-data-container > table {
  width: 100%;
}
.SchoolItem-data-row-container  {
    border-bottom: 2px dashed var(--light-primary-color);
}
.SchoolItem-data-container .SchoolItem-data-row-container {
  margin-top: 10px;
}
.SchoolItem-data-bii {
  display: block;
  font-size: 10px;
}
.SchoolItem-score-data {
  font-size: 1.2em;
  padding-right: 1px;
}
.SchoolItem-score-text {
  font-size: 1em;
  font-weight: lighter;
}
.SchoolItem-score-extra-text {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: var(--small-long-text-color);
}
.SchoolItem-score-data {
  font-weight: bold;
  vertical-align: baseline;
}
.SchoolItem-score-text {
  font-weight: normal;
  margin-left: 2px;
  vertical-align: baseline;
}
.SchoolItem-detail-link {
  text-decoration: underline;
}
.SchoolItem-school-high {
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.SchoolItem-school-both {
  display: flex;
  flex-direction: row;
}
.SchoolItem-data-source {
  font-size: 11px;
  color: var(--small-long-text-color)
}
.SchoolItem-inline-data-source {
  font-size: 11px;
  color: #484848;
  display: block;
  margin-bottom: 6px;
  margin-top: 2px;
}
.SchoolItem-back-link {
  margin-bottom: 14px;
  display: block;
}
.SchoolItem-recomend-value {
  width: 100%;
  margin-top: -20px;
}
.SchoolItem-recomend-alternatives  {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  font-size: 11px;
  margin-bottom: 8px;
}
.SchoolItem-data-list > li {
  margin-top: 8px;
}
.SchoolItem-data-recomend-item {
  max-width: 420px;
}
.SchoolItem-data-source-section {
  margin-top: 40px;
}
.Placeholder-hide {
  opacity: 0;
}
.Placeholder-container {
  margin-left: 30px;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.Placeholder-title {
  width: 200px;
  height: 30px;
  background-color: red;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.Placeholder-text {
  width: 150px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 2px;
}
.Placeholder-breath {
  background: -webkit-linear-gradient(330deg, #888 50%,#999 55%,#888 60%);
  background: linear-gradient(120deg, #888 50%,#999 55%,#888 60%);
  background-size: 300%;
  -webkit-animation: breath 2s ease-in-out infinite;
          animation: breath 2s ease-in-out infinite;
}
.SchoolItem-feedback-button {
  /* padding: 6px; */
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  display: inline-flex;
  width: 59px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 7px;
  /* padding-left: 6px; */
  border: 1px solid var(--color-light-primary-color);
  cursor: pointer;
}
.SchoolItem-feedback-button-good {
  /* border-color: #46b746; */
  margin-left: 20px;
}
.SchoolItem-feedback-button-bad {
  /* border-color: #e26a6a; */
}
.SchoolItem-feedback-container {
  border: 1px solid;
  border-radius: 4px;
  padding: 6px;
  padding-left: 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-size: 14px;
  max-width: 480px;
  display: flex;
  align-items: center;
}
.SchoolItem-feedback-input {
  font-size: 12px;
  height: 20px;
  border: none;
  border-radius: 4px;
  flex-grow: 1;
  margin: 4px;
  margin-left: 10px;
}
@-webkit-keyframes breath {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 20px 0;
  }
}
@keyframes breath {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 20px 0;
  }
}
a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}
article p {
  max-width: 510px;
}

.Badge-container {
  /* width: 70px; */
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(220, 220, 220);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: black;
  font-weight: bolder;
  text-align: center;
}
.Badge-color-1 {
  background-color: #87ce84;
  letter-spacing: 0px;
  font-size: 12px;
  width: 73px;
}
.Badge-color-2 {
  background-color: #efc092;
  font-size: 13px;
  width: 34px;
}
.Badge-color-3 {
  background-color: #e29ccf;
  letter-spacing: 0px;
  font-size: 11px;
  width: 72px;
}
.Badge-color-4 {
  background-color: #98d1e2;
  width: 50px;
}
.Badge-color-5 {
  background-color: #ed9693;
  font-size: 12px;
  width: 50px;
}
.Badge-color-6 {
  font-size: 11px;
  width: 57px;
}

.School-Link-Back {
  text-decoration: underline;
}

.Navigation {
  margin-bottom: 0px;
  /* background-color: var(--secondary-color); */
}
nav {
  align-self: flex-start;
  padding: 12px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
nav a {
  /* flex-basis: 0;
  flex-grow: 1; */
  max-width: 200px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 13px;
  position: relative;
  white-space: nowrap;
  width: unset;
  /* margin-bottom: 22px; */
}
nav a:nth-child(2) {
}

