.ColorModeSwitch-container {
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.ColorModeSwitch-Item {
  text-align: center;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ColorModeSwitch-Item span:first-child {
  font-size: 28px;
  transform: scale(0.8);
}
.ColorModeSwitch-small {
  font-size: 8px;
}
.ColorModeSwitch-active {
  background-color: var(--secondary-color);
}
