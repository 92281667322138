.SearchBar-input {
  font-family: inherit;
  width: 100%;
  font-size: 23px;
  border-style: none;
  outline: none;
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 10px;
  border-radius: 5px;
  /* border: 2px solid var(--accent-color); */
  -webkit-appearance: none;
}
.SearchBar-input-form {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
