/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:500,700');
:root {
  --color-body-bg: white;
  --color-text-color: black;
  --color-title-color: rgb(21, 19, 19);/*rgb(35, 29, 29);*/
  --color-small-title-color: rgb(35, 29, 29); /*rgb(74, 53, 52);*/
  --color-sub-title-color: rgb(44, 36, 34);
  --color-small-text-color: var(--text-color);
  --color-small-long-text-color: #484848;
  --color-line-color: #404040;
  --color-primary-color: var(--text-color);
  --color-light-primary-color: #909090;
  --color-secondary-color: rgb(243, 241, 241);
  --color-shadow-color: #b9b9b9;

  --accent-color: #e0a068;

  --dark-color-body-bg: black;
  --dark-color-text-color: white;
  --dark-color-title-color: rgb(211, 211, 211);
  --dark-color-small-title-color: rgb(235, 235, 235);
  --dark-color-sub-title-color: rgb(195, 195, 195);
  --dark-color-small-text-color: var(--text-color);
  --dark-color-small-long-text-color: #cfcfcf;
  --dark-color-line-color: #c7c7c7;
  --dark-color-primary-color: var(--text-color);
  --dark-color-light-primary-color: #909090;
  --dark-color-secondary-color: rgb(57, 57, 57);
  --dark-color-shadow-color: var(--body-bg);

  --body-bg: var(--color-body-bg);
  --text-color: var(--color-text-color);
  --title-color: var(--color-title-color);
  --small-title-color: var(--color-small-title-color);
  --sub-title-color: var(--color-sub-title-color);
  --small-text-color: var(--color-small-text-color);
  --small-long-text-color: var(--color-small-long-text-color);
  --line-color: var(--color-line-color);
  --primary-color: var(--color-primary-color);
  --light-primary-color: var(--color-light-primary-color);
  --secondary-color: var(--color-secondary-color);
  --shadow-color: var(--color-shadow-color);
}
::placeholder {
  color: var(--small-long-text-color)
}
body {
  background-color: var(--body-bg);
  color: var(--text-color);
  font-family: 'IBM Plex Sans', sans-serif;
  padding:  2px;
}
a {
  cursor: pointer;
}
.App {
  z-index: 1;
  background-color: var(--body-bg);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.App-overlay {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  filter: none;
  background-color: rgba(248, 245, 248, 0.8);
  display: flex;
  flex-direction: column;
  color: black;
  z-index: 100;
  overflow: scroll;
}
.App-overlay-close {
  font-size: 24px;
  font-weight: bolder;
  cursor: pointer;
  padding-right: 20px;
  padding-top: 10px;
  margin-bottom: -20px;
  align-self: flex-end;
}
.App-hidden {
  visibility: hidden;
}
.App-overlay-table {
  width: 80px;
  margin-left: 40px;
}
.App-overlay p {
  font-weight: normal;
  line-height: 1.5;
  margin: 20px;
}
.App-overlay:not(.App-hidden) ~ * {
  filter: blur(4px);
  z-index: 1;
}
.App-overlay-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  font-size: 28px;
  margin: 20px;
}
.App-top {
  z-index: 2;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  background-color: var(--body-bg);
  /* box-shadow: 0 2px 5px #00000038; */
  /* border-radius: 12px; */
}
.App-footer {
  /* z-index: 2; */
  height: 80px;
  /* background-color: rgb(243, 241, 241); */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-top: 3px dashed var(--line-color);
}
.App-emoji {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 13px;
  margin-bottom: 2px;
}
.App-footer-sub {
  font-size: 10px;
  margin-top: 4px;
}
.App-footer-sub-link {
  text-decoration: underline;
  cursor: pointer;
}
.App-nav-active {
  background-color: var(--primary-color);
  color: var(--body-bg);
  border-radius: 2px;
}
.App-grade-small-pill {
  position: absolute;
  top: -12px;
  right: -10px;
  border-radius: 8px;
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  color: var(--body-bg);
  background-color: var( --primary-color);
}
.App-nav-active .App-grade-small-pill {
  display: none;
}
.about-link {
  text-decoration: underline;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark'
  }
  :root:not([data-color-mode]) {
    --body-bg: var(--dark-color-body-bg);
    --text-color: var(--dark-color-text-color);
    --title-color: var(--dark-color-title-color);
    --small-title-color: var(--dark-color-small-title-color);
    --sub-title-color: var(--dark-color-sub-title-color);
    --small-text-color: var(--dark-color-small-text-color);
    --small-long-text-color: var(--dark-color-small-long-text-color);
    --line-color: var(--dark-color-line-color);
    --primary-color: var(--dark-color-primary-color);
    --light-primary-color: var(--dark-color-light-primary-color);
    --secondary-color: var(--dark-color-secondary-color);
    --shadow-color: var(--dark-color-shadow-color);
  }
}

[data-color-mode='dark'] {
  --body-bg: var(--dark-color-body-bg);
  --text-color: var(--dark-color-text-color);
  --title-color: var(--dark-color-title-color);
  --small-title-color: var(--dark-color-small-title-color);
  --sub-title-color: var(--dark-color-sub-title-color);
  --small-text-color: var(--dark-color-small-text-color);
  --small-long-text-color: var(--dark-color-small-long-text-color);
  --line-color: var(--dark-color-line-color);
  --primary-color: var(--dark-color-primary-color);
  --light-primary-color: var(--dark-color-light-primary-color);
  --secondary-color: var(--dark-color-secondary-color);
  --shadow-color: var(--dark-color-shadow-color);
}


.secret-santa-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #841616;
  color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 44px;
}
.secret-santa-inner-container {
  padding: 10px;
  margin: 20px;
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  /* min-height: 90%; */
  display: flex;
  background-color: #841616;
  border: 8px dashed white;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* overflow-y: scroll; */
}
.secret-santa-name-title {
  margin-top: 60px;
  text-align: center;
  font-size: 44px;
}
.secret-santa-sub-title {
  /* padding: 4px; */
  font-size: 18px;
  text-align: center;
  max-width: 600px;
}
.secret-santa-fluff {
  width: 30%;
  max-width: 400px;
  position: absolute;
  top: 0;
  right: 0;
}
