.SchoolItem-item {
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: background-color .1s;
  /* border-top: 1px solid rgb(130, 130, 130); */
}
.SchoolItem-item:hover {
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.SchoolItem-open {
  background-color: var(--secondary-color);
  border-radius: 6px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 30px;
}
.SchoolItem-open .SchoolItem-title-container {
  padding-bottom: 12px;
}
.SchoolItem-line {
  height: 2px;
  width: 100%;
  background-color: rgb(200, 200, 200, 0.7);
  margin-top: 4px;
}
.SchoolItem-school-title {
  color: var(--title-color);/*rgb(21, 19, 19);*/
  margin-top: 0;
  word-break: break-word;
}
.SchoolItem-school-program {
  font-weight: normal;
  font-size: .9em;
  margin: 0;
  color: var(--sub-title-color);
  line-height: 1.2;
  word-break: break-word;
}
.SchoolItem-score-table {
  /* align-self: flex-end; */
  margin-bottom: -3px;
  color: var(--sub-title-color);
  margin-left: 20px;
}
.SchoolItem-data-title-container {
  display: flex;
  justify-content: space-between;
}
.SchoolItem-data-row {
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 2px;
}
.SchoolItem-data-row-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
}
.SchoolItem-data-row-container:not(.SchoolItem-data-row-container-column) {
  padding-bottom: 4px;
}
.SchoolItem-data-row-container-column {
  flex-direction: column;
  align-items: flex-end;
}
.SchoolItem-data-row-title {
  margin-right: 16px;
}
.SchoolItem-data-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  background-color: var(--secondary-color);
  border-radius: 2px;
  box-shadow: -1px 2px 5px 0px var(--shadow-color);
  /* border: 2px solid; */
  padding: 10px;
}
.SchoolItem-data-share {
  text-decoration: underline;
  cursor: pointer;
}
.SchoolItem-data-container > .SchoolItem-data-title-container > h3 {
  font-weight: lighter;
}
.SchoolItem-data-container > .SchoolItem-data-title-container > h3 > span {
  font-weight: bold
}
.SchoolItem-data-container > table {
  width: 100%;
}
.SchoolItem-data-row-container  {
    border-bottom: 2px dashed var(--light-primary-color);
}
.SchoolItem-data-container .SchoolItem-data-row-container {
  margin-top: 10px;
}
.SchoolItem-data-bii {
  display: block;
  font-size: 10px;
}
.SchoolItem-score-data {
  font-size: 1.2em;
  padding-right: 1px;
}
.SchoolItem-score-text {
  font-size: 1em;
  font-weight: lighter;
}
.SchoolItem-score-extra-text {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: var(--small-long-text-color);
}
.SchoolItem-score-data {
  font-weight: bold;
  vertical-align: baseline;
}
.SchoolItem-score-text {
  font-weight: normal;
  margin-left: 2px;
  vertical-align: baseline;
}
.SchoolItem-detail-link {
  text-decoration: underline;
}
.SchoolItem-school-high {
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.SchoolItem-school-both {
  display: flex;
  flex-direction: row;
}
.SchoolItem-data-source {
  font-size: 11px;
  color: var(--small-long-text-color)
}
.SchoolItem-inline-data-source {
  font-size: 11px;
  color: #484848;
  display: block;
  margin-bottom: 6px;
  margin-top: 2px;
}
.SchoolItem-back-link {
  margin-bottom: 14px;
  display: block;
}
.SchoolItem-recomend-value {
  width: 100%;
  margin-top: -20px;
}
.SchoolItem-recomend-alternatives  {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  font-size: 11px;
  margin-bottom: 8px;
}
.SchoolItem-data-list > li {
  margin-top: 8px;
}
.SchoolItem-data-recomend-item {
  max-width: 420px;
}
.SchoolItem-data-source-section {
  margin-top: 40px;
}
.Placeholder-hide {
  opacity: 0;
}
.Placeholder-container {
  margin-left: 30px;
  transition: opacity .5s;
}
.Placeholder-title {
  width: 200px;
  height: 30px;
  background-color: red;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.Placeholder-text {
  width: 150px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 2px;
}
.Placeholder-breath {
  background: linear-gradient(120deg, #888 50%,#999 55%,#888 60%);
  background-size: 300%;
  animation: breath 2s ease-in-out infinite;
}
.SchoolItem-feedback-button {
  /* padding: 6px; */
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  display: inline-flex;
  width: 59px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: 7px;
  /* padding-left: 6px; */
  border: 1px solid var(--color-light-primary-color);
  cursor: pointer;
}
.SchoolItem-feedback-button-good {
  /* border-color: #46b746; */
  margin-left: 20px;
}
.SchoolItem-feedback-button-bad {
  /* border-color: #e26a6a; */
}
.SchoolItem-feedback-container {
  border: 1px solid;
  border-radius: 4px;
  padding: 6px;
  padding-left: 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-size: 14px;
  max-width: 480px;
  display: flex;
  align-items: center;
}
.SchoolItem-feedback-input {
  font-size: 12px;
  height: 20px;
  border: none;
  border-radius: 4px;
  flex-grow: 1;
  margin: 4px;
  margin-left: 10px;
}
@keyframes breath {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 20px 0;
  }
}
a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}
article p {
  max-width: 510px;
}
