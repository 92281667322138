.GradePicker-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  width: 100%;
  margin-bottom: 40px;
}
.GradePicker-grades {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}
.GradePicker-subj {
  font-size: 22px;
  padding-top: 6px;
  font-weight: normal;
  color: var(--small-title-color);
}
.GradePicker-grade {
  background-color: rgba(135, 129, 129, 0.3);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin: 0;
  font-size: 18px;
}
.GradePicker-grade:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.GradePicker-grade:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.GradePicker-active {
  background-color: #87ce84;
  font-weight: bolder;
}
@media screen and (max-width: 580px) {
  .GradePicker-container {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .GradePicker-subj {
    margin-bottom: 10px;
  }
  .GradePicker-grades {
    justify-content: flex-start;
  }
}
