.Filter-Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 12px;
}
.Filter-title {
  margin: 0;
  align-self: flex-start;
  color: var(--small-long-text);
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}
.Filter {
  /* margin-left: 10px; */
}
