.Filter-Icon-Container {
  padding-left: 8px;
  padding-right: 8px;
  height: 26px;
  cursor: pointer;
  color: var(--text-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
}
.Filter-Icon-Container:nth-child(1) {
  margin-left: 0;
}
.Filter-Icon-Active {
  background-color: var(--small-title-color);
  border-color: var(--light-primary-color);
  color: var(--body-bg);
}
